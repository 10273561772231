<template>
  <a-form-item
    name="siret"
    label="SIRET"
    :rules="[
      { required: true, message: 'Veuillez saisir un numéro de SIRET !' },
    ]"
  >
    <a-input
      v-model:value="computedFormState.siret"
      :onChange="onSiretChange"
    />

    <!--    <button type="button" @click="searchSiret()">Recherche</button>-->
  </a-form-item>

  <ContainerFieldsHorizontal>
    <template #1>
      <a-form-item
        name="name"
        label="Nom"
        :rules="[{ required: true, message: 'Veuillez saisir le nom !' }]"
      >
        <a-input v-model:value="computedFormState.name" />
      </a-form-item>
    </template>
    <template #2>
      <a-form-item name="legalStatus" label="Forme sociale">
        <a-select v-model:value="computedFormState.legalStatus">
          <a-select-option
            v-for="option in legalStatusOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>
  </ContainerFieldsHorizontal>

  <a-form-item
    name="address"
    label="Adresse"
    :rules="[{ required: true, message: 'Veuillez saisir l\'adresse !' }]"
  >
    <vue-google-autocomplete
      ref="address"
      :id="mapId"
      classname="ant-input"
      placeholder="Renseignez l’adresse"
      v-on:placechanged="getAddressData"
      @blur="onBlurAddress()"
    >
    </vue-google-autocomplete>
  </a-form-item>

  <ContainerFieldsHorizontal>
    <template #1
      ><a-form-item
        name="estimatedValue"
        label="Valeur estimée totale"
        :rules="[
          {
            required: true,
            message: 'Veuillez saisir la valeur estimée totale',
          },
        ]"
      >
        <InputCurrency
          v-model:value="computedFormState.estimatedValue"
        /> </a-form-item
    ></template>
    <template #2>
      <a-form-item
        name="kind"
        label="Type de société"
        :rules="[
          { required: true, message: 'Veuillez choisir un type de société !' },
        ]"
      >
        <a-select v-model:value="computedFormState.kind">
          <a-select-option
            v-for="option in kindOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item></template
    >
  </ContainerFieldsHorizontal>

  <a-form-item
    label="Société à prépondérance immobilière ?"
    name="realEstateCompany"
  >
    <a-checkbox v-model:checked="computedFormState.realEstateCompany" />
  </a-form-item>

  <a-form-item label="S’agit-il d’un bien d’investissement ?" name="investment">
    <a-checkbox v-model:checked="computedFormState.investment" />
  </a-form-item>
</template>

<script>
import InputCurrency from "@/components/Fields/InputCurrency";
import CompanyApi from "@/api/company";
import {
  kindOptions,
  legalStatusOptions,
} from "@/views/App/ValuableObjects/Company/constants";
import ContainerFieldsHorizontal from "@/components/Fields/ContainerFieldsHorizontal";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import _ from "lodash";

export default {
  name: "TabDescription",
  components: {
    ContainerFieldsHorizontal,
    InputCurrency,
    VueGoogleAutocomplete,
  },
  props: ["formState", "mapId"],
  data() {
    return {
      legalStatusOptions,
      kindOptions,
    };
  },
  mounted() {
    this.$refs.address.update(this.formState.address);
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.computedFormState.address = placeResultData.formatted_address;
    },
    onBlurAddress() {
      this.$refs.address.update(this.computedFormState.address);
    },
    searchSiret() {
      CompanyApi.getCompanyInfo(this.computedFormState.siret).then((data) => {
        const addressAlreadyExists =
          !_.isNil(this.computedFormState.address) &&
          this.computedFormState.address.length > 0;

        this.computedFormState.name = data.name;
        this.computedFormState.legalStatus = data.legalStatus;

        if (!addressAlreadyExists) {
          this.computedFormState.address = data.address;
          this.$refs.address.update(this.computedFormState.address);
        }
      });
    },
    onSiretChange(v) {
      this.computedFormState.siret = v.target.value.replace(/\D/g, "");

      if (this.computedFormState.siret.length === 14) {
        this.searchSiret();
      }
    },
  },
};
</script>

<style scoped></style>
