<template>
  <div>
    <a-modal
      v-model:visible="visible"
      width="80%"
      ok-text="Sauvegarder"
      cancel-text="Annuler"
      @ok="onOk"
      @cancel="onCancel"
    >
      <template #title>
        <Heading6>{{ modalTitle }}</Heading6>
      </template>
      <a-form
        layout="vertical"
        autocomplete="off"
        ref="formRef"
        :model="formState"
        name="form_in_modal"
      >
        <a-form-item
          name="name"
          label="Nom"
          :rules="[
            { required: true, message: 'Veuillez saisir le nom du bien !' },
          ]"
        >
          <a-input v-model:value="formState.name" />
        </a-form-item>

        <a-form-item name="description" label="Description">
          <a-textarea v-model:value="formState.description" />
        </a-form-item>

        <a-form-item
          name="kind"
          label="Type"
          :rules="[
            {
              required: true,
              message: 'Veuillez sélectionner le type du bien !',
            },
          ]"
        >
          <a-select
            v-model:value="formState.kind"
            placeholder="Choisissez le type de bien"
          >
            <a-select-option
              v-for="option in options"
              :value="option.value"
              :key="option.value"
              >{{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <div class="wrap-label-investment">
          <a-form-item
            name="investment"
            label="S’agit-il d’un bien d’investissement ?"
          >
            <a-radio-group v-model:value="formState.investment">
              <a-radio :value="true">Oui</a-radio>
              <a-radio :value="false">Non</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>

        <a-form-item
          name="currentValue"
          label="Valeur actuelle"
          :rules="[
            {
              required: true,
              message: 'Veuillez saisir la valeur actuelle du bien !',
            },
          ]"
        >
          <InputCurrency
            v-model:value="formState.currentValue"
            placeholder="Renseignez la valeur actuelle"
          />
        </a-form-item>

        <ContainerFieldsHorizontal>
          <template #1>
            <a-form-item name="acquisitionValue" label="Valeur d'acquisition">
              <InputCurrency
                v-model:value="formState.acquisitionValue"
                placeholder="Renseignez la valeur d’acquisition"
              />
            </a-form-item>
          </template>

          <template #2>
            <a-form-item name="acquisitionDate" label="Date d'acquisition">
              <a-date-picker
                class="w-100"
                v-model:value="formState.acquisitionDate"
                placeholder="JJ/MM/AAAA"
                format="DD/MM/YYYY"
              />
            </a-form-item>
          </template>
        </ContainerFieldsHorizontal>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import ValuableObjectApi from "@/api/valuableObject";
import dayjs from "dayjs";
import { kindOptions } from "@/views/App/ValuableObjects/constants";
import InputCurrency from "@/components/Fields/InputCurrency";
import Heading6 from "@/components/Texts/Heading6";
import ContainerFieldsHorizontal from "@/components/Fields/ContainerFieldsHorizontal";
import { catchSubmitForm } from "@/views/App/ValuableObjects/utils";

export default defineComponent({
  name: "FormEditValuableObject",
  components: { ContainerFieldsHorizontal, Heading6, InputCurrency },
  props: [
    "onHide",
    "valuableObject",
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "updateValuableObject",
  ],

  data() {
    return {
      options: kindOptions,
    };
  },
  setup(props) {
    const formItemLayout = {
      labelCol: { span: 4 },
      //wrapperCol: { span: 8 },
    };

    const formRef = ref();
    const visible = ref(true);

    const formState = reactive({
      ...props.valuableObject,
      acquisitionDate: !props.valuableObject.acquisitionDate ? null : dayjs(props.valuableObject.acquisitionDate),
    });

    const onOk = () => {
      formRef.value
        .validateFields()
        .then((values) => {
          ValuableObjectApi.editValuableObject(props.valuableObject._id, values)
            .then((data) => {
              props.updateValuableObject(data);
              visible.value = false;
              props.onHide();
            })
            .catch(catchSubmitForm);
        })
        .catch((error) => {
          // Form invalid, e.g. field required without value

          alert("Tous les champs ne sont pas complétés");
          // TODO redirect to "first" tab with an error like "create form"
          console.error("[ValuableObject] Error:", error);
        });
    };

    const onCancel = () => {
      props.onHide();
    };

    return {
      formState,
      formRef,
      visible,
      onOk,
      onCancel,
      formItemLayout,
    };
  },
});
</script>
<style></style>
