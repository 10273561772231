<template>
  <div>
    <a-modal
      v-model:visible="visible"
      width="80%"
      ok-text="Sauvegarder"
      cancel-text="Annuler"
      @ok="onOk"
      @cancel="onCancel"
    >
      <template #title>
        <Heading6>{{ modalTitle }}</Heading6>
      </template>
      <a-form
        layout="vertical"
        autocomplete="off"
        ref="formRef"
        :model="formState"
        name="form_in_modal"
      >
        <FormCreateRealEstate
          v-model:formState="formState"
          v-model:activeKey="activeKey"
          mapId="map-update"
        />
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import dayjs from "dayjs";
import RealEstateApi from "@/api/realEstate";
import FormCreateRealEstate from "@/views/App/ValuableObjects/RealEstate/FormCreateRealEstate";
import _ from "lodash";
import Heading6 from "@/components/Texts/Heading6";
import {
  catchSubmitForm,
  populateValues,
} from "@/views/App/ValuableObjects/utils";

export default defineComponent({
  name: "FormEditRealEstate",
  components: { Heading6, FormCreateRealEstate },
  props: [
    "onHide",
    "realEstate",
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "updateRealEstate",
  ],
  setup(props) {
    const formItemLayout = {
      labelCol: { span: 4 },
    };

    const formRef = ref();
    const visible = ref(true);
    const activeKey = ref("1");

    const formState = reactive({
      ..._.omit(props.realEstate, [
        "_id",
        "createdAt",
        "updatedAt",
        "family",
        "customer",
        "ownerValue",
      ]),
      acquisitionDate: !props.realEstate.acquisitionDate ? null : dayjs(props.realEstate.acquisitionDate),
      owners: _.map(props.realEstate.owners, (o) =>
        _.pick(o, ["owner", "ownershipType", "percentage"])
      ),
    });

    const onOk = () => {
      formRef.value
        .validateFields()
        .then((values) => {
          RealEstateApi.editRealEstate(
            props.realEstate._id,
            populateValues(formState, values)
          )
            .then((data) => {
              props.updateRealEstate(data);
              visible.value = false;
              props.onHide();
            })
            .catch(catchSubmitForm);
        })
        .catch((error) => {
          // Form invalid, e.g. field required without value

          alert("Tous les champs ne sont pas complétés");
          // TODO redirect to "first" tab with an error like "create form"
          console.error("[RealEstate] Error:", error);
        });
    };

    const onCancel = () => {
      props.onHide();
    };

    return {
      formState,
      formRef,
      visible,
      onOk,
      onCancel,
      formItemLayout,
      activeKey,
    };
  },
});
</script>
<style>
.wrap-label-investment .ant-form-item-label {
  white-space: normal;
  margin-top: 5px;
}

.wrap-label-investment .ant-form-item-label > label::after {
  top: -11px;
}
</style>
