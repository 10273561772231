import _ from "lodash";

/*
société civile,
SCI,
SARL/EURL/SELARL,
SA,
SAS/SASU/SELAS,
SCA,
SCS,
SCM,
SCPI,
autre
 */

const SOCIETE_CIVILE = "societe-civile";
const SCI = "sci";
const SARL_EURL_SELARL = "sarl_eurl_selarl";
const SA = "sa";
const SAS_SASU_SELAS = "sas_sasu_selas";
const SCA = "sca";
const SCS = "scs";
const SCM = "scm";
const SCPI = "scpi";
const OTHER = "other";

export const legalStatusLabels = {
  [SOCIETE_CIVILE]: "Société civile",
  [SCI]: "SCI",
  [SARL_EURL_SELARL]: "SARL/EURL/SELARL",
  [SA]: "SA",
  [SAS_SASU_SELAS]: "SAS/SASU/SELAS",
  [SCA]: "SCA",
  [SCS]: "SCS",
  [SCM]: "SCM",
  [SCPI]: "SCPI",
  [OTHER]: "Autre",
};

export const legalStatusOptions = _.map(legalStatusLabels, (label, key) => ({
  label: label,
  value: key,
}));

// --------------------------

const HOLDING = "holding";
const STARTUP = "startup";
const PROFESSIONAL_ACTIVITY = "professional-activity";

export const kindLabels = {
  [HOLDING]: "Holding",
  [STARTUP]: "Startup",
  [PROFESSIONAL_ACTIVITY]: "Activité professionnelle",
  [SCI]: "SCI",
  [OTHER]: "Autres"
};

export const kindOptions = _.orderBy(
  _.map(kindLabels, (label, key) => ({
    label: label,
    value: key,
  })),
  ["label"]
);
