// deduction available
import HTTP from "@/axios";

export default {
  getCompanyInfo(siret) {
    return new Promise((resolve, reject) => {
      HTTP.get(`company/info/${siret}`)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCompanies() {
    return new Promise((resolve, reject) => {
      HTTP.get("company/")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCompany(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("company/", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editCompany(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(`company/${id}/`, data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCompany(id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`company/${id}/`)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
