<template>
  <a-row :gutter="16">
    <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :xxl="12" :xxxl="12"
      ><slot name="1"></slot
    ></a-col>
    <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :xxl="12" :xxxl="12"
      ><slot name="2"></slot
    ></a-col>
  </a-row>
</template>

<script>
export default {
  name: "ContainerFieldsHorizontal",
};
</script>

<style scoped></style>
