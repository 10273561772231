<template>
  <div>
    <a-modal
      v-model:visible="visible"
      width="80%"
      :ok-button-props="{ disabled: categorySelected === null }"
      :ok-text="getModalOkText()"
      cancel-text="Annuler"
      @ok="onOk"
      @cancel="onCancel"
      :bodyStyle="{ padding: '32px', paddingTop: 0 }"
    >
      <template #title>
        <Heading6>{{ modalTitle }}</Heading6>
      </template>
      <div
        style="margin-top: 32px"
        class="selection-cat"
        v-if="State.Selection === stateRef"
      >
        <InputSelectCard
          :options="categoriesOptions"
          :onChange="setCategory"
          :value="categorySelected"
        ></InputSelectCard>
      </div>

      <div v-else>
        <a-form
          layout="vertical"
          v-if="categorySelected === 'immo'"
          autocomplete="off"
          ref="formRefImmo"
          :model="formStateImmo"
          name="form_in_modal"
        >
          <FormCreateRealEstate
            v-model:formState="formStateImmo"
            v-model:activeKey="activeKeyImmo"
            mapId="map-create"
          />
        </a-form>
        <a-form
          layout="vertical"
          v-else-if="categorySelected === 'company'"
          autocomplete="off"
          ref="formRefCompany"
          :model="formStateCompany"
          name="form_in_modal"
        >
          <FormCreateCompany
            v-model:formState="formStateCompany"
            v-model:activeKey="activeKeyCompany"
            mapId="map-create-company"
          />
        </a-form>
        <a-form
          layout="vertical"
          v-else
          autocomplete="off"
          ref="formRef"
          :model="formState"
          name="form_in_modal"
        >
          <a-form-item
            name="name"
            label="Nom"
            :rules="[
              { required: true, message: 'Veuillez saisir le nom du bien !' },
            ]"
          >
            <a-input v-model:value="formState.name" />
          </a-form-item>

          <a-form-item name="description" label="Description">
            <a-textarea v-model:value="formState.description" />
          </a-form-item>

          <a-form-item
            name="kind"
            label="Type"
            :rules="[
              {
                required: true,
                message: 'Veuillez selectionner le type du bien !',
              },
            ]"
          >
            <a-select
              v-model:value="formState.kind"
              placeholder="Choisissez le type de bien"
            >
              <a-select-option
                v-for="option in options"
                :value="option.value"
                :key="option.value"
                >{{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <div class="wrap-label-investment">
            <a-form-item
              name="investment"
              label="S’agit-il d’un bien d’investissement ?"
            >
              <a-radio-group v-model:value="formState.investment">
                <a-radio :value="true">Oui</a-radio>
                <a-radio :value="false">Non</a-radio>
              </a-radio-group>
            </a-form-item>
          </div>

          <a-form-item
            name="currentValue"
            label="Valeur actuelle"
            :rules="[
              {
                required: true,
                message: 'Veuillez saisir la valeur actuelle du bien !',
              },
            ]"
          >
            <InputCurrency
              v-model:value="formState.currentValue"
              placeholder="Renseignez la valeur actuelle"
            />
          </a-form-item>

          <ContainerFieldsHorizontal>
            <template #1>
              <a-form-item name="currentValue" label="Valeur estimée">
                <InputCurrency
                  v-model:value="formState.currentValue"
                  placeholder="Renseignez la valeur estimée"
                />
              </a-form-item>
            </template>

            <template #2>
              <a-form-item name="acquisitionDate" label="Date d'acquisition">
                <a-date-picker
                  class="w-100"
                  v-model:value="formState.acquisitionDate"
                  placeholder="JJ/MM/AAAA"
                  format="DD/MM/YYYY"
                />
              </a-form-item>
            </template>
          </ContainerFieldsHorizontal>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import ValuableObjectApi from "@/api/valuableObject";
import RealEstateApi from "@/api/realEstate";
import CompanyApi from "@/api/company";
import { kindOptions } from "@/views/App/ValuableObjects/constants";
import InputCurrency from "@/components/Fields/InputCurrency";
import InputSelectCard from "@/components/Fields/InputSelectCard";
import FormCreateRealEstate from "@/views/App/ValuableObjects/RealEstate/FormCreateRealEstate";
import FormCreateCompany from "@/views/App/ValuableObjects/Company/FormCreateCompany";
import _ from "lodash";
import Heading6 from "@/components/Texts/Heading6";
import ContainerFieldsHorizontal from "@/components/Fields/ContainerFieldsHorizontal";
import { catchSubmitForm } from "@/views/App/ValuableObjects/utils";

const State = Object.freeze({ Selection: 0, Form: 1 });

const categoriesOptions = [
  { label: "Immobilier", value: "immo" },
  { label: "Société", value: "company" },
  { label: "Autre", value: "autre" },
];

export default defineComponent({
  name: "FormCreateValuableObject",
  components: {
    ContainerFieldsHorizontal,
    Heading6,
    FormCreateCompany,
    FormCreateRealEstate,
    InputSelectCard,
    InputCurrency,
  },
  props: [
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "addValuableObject",
    "addRealEstate",
    "addCompany",
    "hideModal",
  ],

  data() {
    return {
      options: kindOptions,
      State,
      categoriesOptions,
      visible: true,
    };
  },
  watch: {
    visible: function (newVal) {
      // watch it
      console.log("watch, visible", newVal);
      if (newVal === false) {
        this.hideModal();
      }
    },
  },
  setup(props) {
    const formItemLayout = {
      labelCol: { span: 4 },
      //wrapperCol: { span: 8 },
    };

    const categorySelected = ref(null);

    const setCategory = (cat) => {
      categorySelected.value = cat;
    };

    const stateRef = ref(State.Selection);

    /*
      ValuableObject
     */

    const formRef = ref();
    //const visible = ref(false);
    const formState = reactive({
      name: "",
      description: "",
      kind: "",
      investment: null,
      currentValue: null,
      acquisitionValue: null,
      acquisitionDate: null,
    });

    /*
      RealEstate
     */

    const formStateImmo = reactive({
      // Step [Description]

      address: "",
      address_google_maps: null, // structured format from google maps api
      mainResidence: false, // Ceci est ma residence pricinpale
      realEstateKind: "", // Type d’immobilier  de jouissance   de rapport
      currentValue: null, // Valeur estimée :
      acquisitionDate: null,
      habitableSurface: null, // Surface habitable :
      constructionYear: null, // Année de construction :

      // Step [Propriété]
      // this is an array

      owners: [],
      /*
      owner: null,  // Nom du propiétaire * :
      ownershipType: null,  // Type de propriété *:
      percentage: null,  // Pourcentage de propriété * :
       */

      // Step [Propriété]
      energyLabel: null, // Label énergétique :
      floorNumber: null, // Etage
      roomNumber: null, // Pièces
      bathroomNumber: null, // Salles de bain
      garageNumber: null, // Places de garage
      parkingNumber: null, // Places de parking
      elevator: false, // Ascenseur
      specificAspects: null, // Particularités
      garden: null, // Jardin M2
      balcony: null, // Balcon/Terrasse M2
      generalCondition: null, // Etat général du bien :
    });
    const formRefImmo = ref();
    const activeKeyImmo = ref("1");

    /*
      Company
     */

    const formStateCompany = reactive({
      // Step [Description]
      siret: null,
      legalStatus: null,
      name: null,
      address: null,
      estimatedValue: null,
      realEstateCompany: null,
      investment: null,
      kind: null,

      // Step [Propriété]
      // this is an array

      owners: [],
      /*
      owner: null,  // Nom du propiétaire * :
      ownershipType: null,  // Type de propriété *:
      percentage: null,  // Pourcentage de propriété * :
       */
    });
    const formRefCompany = ref();
    const activeKeyCompany = ref("1");

    const onOk = () => {
      if (stateRef.value === State.Selection) {
        stateRef.value = State.Form;
        return;
      }

      switch (categorySelected.value) {
        case "immo":
          switch (activeKeyImmo.value) {
            case "1":
              activeKeyImmo.value = "2";
              return;
            case "2":
              activeKeyImmo.value = "3";
              return;
          }

          formRefImmo.value
            .validateFields()
            .then((values) => {
              RealEstateApi.createRealEstate(values)
                .then((data) => {
                  props?.addRealEstate(data);
                })
                .catch(catchSubmitForm);

              props?.hideModal();
            })
            .catch((info) => {
              const pages = {
                address: 1,
                mainResidence: 1,
                realEstateKind: 1,
                currentValue: 1,
                acquisitionDate: 1,
                habitableSurface: 1,
                constructionYear: 1,
                owners: 2,
                energyLabel: 3,
                floorNumber: 3,
                roomNumber: 3,
                bathroomNumber: 3,
                garageNumber: 3,
                parkingNumber: 3,
                elevator: 3,
                specificAspects: 3,
                garden: 3,
                balcony: 3,
                generalCondition: 3,
              };

              activeKeyImmo.value = `${
                pages[_.head(_.get(_.head(info.errorFields), "name"))] || "1"
              }`;
            });
          break;
        case "company":
          switch (activeKeyCompany.value) {
            case "1":
              activeKeyCompany.value = "2";
              return;
          }

          formRefCompany.value
            .validateFields()
            .then((values) => {
              CompanyApi.createCompany(values)
                .then((data) => {
                  props?.addCompany(data);
                })
                .catch(catchSubmitForm);

              props?.hideModal();
            })
            .catch((info) => {
              const pages = {
                /*
              siret: 1,
              legalStatus: 1,
              name: 1,
              address: 1,
              estimatedValue: 1,
              realEstateCompany: 1,
              investment: 1,
              kind: 1,
               */
                owners: 2,
              };

              activeKeyCompany.value = `${
                pages[_.head(_.get(_.head(info.errorFields), "name"))] || "1"
              }`;
            });
          break;
        default:
          formRef.value.validateFields().then((values) => {
            ValuableObjectApi.createValuableObject(values)
              .then((data) => {
                props.addValuableObject(data);
              })
              .catch(catchSubmitForm);

            props?.hideModal();
          });
      }
    };

    const onCancel = () => {
      formRef?.value?.resetFields();
      formRefImmo?.value?.valueOf();
      formRefCompany?.value?.valueOf();
    };

    const getModalOkText = () => {
      if (State.Selection === stateRef.value) {
        return "Suivant";
      }

      switch (categorySelected.value) {
        case "immo":
          if (activeKeyImmo.value !== "3") {
            return "Suivant";
          }
          break;
        case "company":
          if (activeKeyCompany.value !== "2") {
            return "Suivant";
          }
      }
      return "Créer";
    };

    return {
      formStateCompany,
      formRefCompany,
      activeKeyCompany,

      activeKeyImmo,
      formStateImmo,
      formRefImmo,
      formState,
      formRef,
      onOk,
      onCancel,
      formItemLayout,
      stateRef,
      setCategory,
      categorySelected,
      getModalOkText,
    };
  },
});
</script>
<style>
/*
.wrap-label-investment .ant-form-item-label {
  white-space: normal;
  margin-top: 5px;
}

.wrap-label-investment .ant-form-item-label > label::after {
  top: -11px;
}

 */
.selection-cat {
  /*
  min-height: 40vh;
  display: flex;
  align-items: center;

   */
}
.ant-modal-header {
  padding: 32px 32px 13px 32px;
}
.ant-modal-close {
  top: 15px;
  right: 15px;
}
</style>
