<template>
  <div class="container">
    <Card
      v-for="option in options"
      :key="option.value"
      v-on:click="onChange(option.value)"
      class="item card"
      :class="{ selected: value === option.value }"
      >{{ option.label }}</Card
    >
  </div>
</template>

<script>
import Card from "@/components/Cards/Card";
export default {
  name: "InputSelectCard",
  components: { Card },
  props: ["options", "onChange", "value"], // {label, value}
};
</script>

<style scoped>
.container {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
}

.container .item {
  height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
}

.container .item:hover {
  background-color: #ac2369ff;
  color: #fff;
}

.container .item.selected {
  background-color: var(--kermony-office-rose);
  color: #fff;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container {
    grid-auto-flow: row !important;
  }
  .container .item {
    height: 80px !important;
  }
}
</style>
