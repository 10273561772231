import _ from "lodash";

export const PERSONAL_PROPERTY = "personal-property";
const INVESTMENT_PROPERTY = "investment-property";

// realEstateKind: '', // Type d’immobilier  de jouissance   de rapport

export const realEstateKindLabels = {
  [PERSONAL_PROPERTY]: "Immobilier de jouissance",
  [INVESTMENT_PROPERTY]: "Immobilier de rapport",
};

export const realEstateKindOptions = _.orderBy(
  _.map(realEstateKindLabels, (label, key) => ({
    label: label,
    value: key,
  })),
  ["label"]
);

// -------------------------------

const LABEL_A = "label_a";
const LABEL_B = "label_b";
const LABEL_C = "label_c";
const LABEL_D = "label_d";
const LABEL_E = "label_e";
const LABEL_F = "label_f";
const LABEL_G = "label_g";

export const energyLabelKindLabels = {
  [LABEL_A]: "Label A",
  [LABEL_B]: "Label B",
  [LABEL_C]: "Label C",
  [LABEL_D]: "Label D",
  [LABEL_E]: "Label E",
  [LABEL_F]: "Label F",
  [LABEL_G]: "Label G"
};

export const energyLabelKindOptions = _.orderBy(
  _.map(energyLabelKindLabels, (label, key) => ({
    label: label,
    value: key,
  })),
  ["label"]
);

// -------------------------------

const AMAZING_VIEW = "amazing_view";

export const specificAspectsKindLabels = {
  [AMAZING_VIEW]: "Vue incroyable",
};

export const specificAspectsKindOptions = _.orderBy(
  _.map(specificAspectsKindLabels, (label, key) => ({
    label: label,
    value: key,
  })),
  ["label"]
);

// -------------------------------

//generalCondition Entrée de gamme

const ENTRY_LEVEL = "entry-level";
const MID_RANGE = "mid-range";
const LUXURIOUS = "luxurious";
const VERY_LUXURIOUS = "very-luxurious";

export const generalConditionKindLabels = {
  [ENTRY_LEVEL]: "Entrée de gamme",
  [MID_RANGE]: "Moyen de gamme",
  [LUXURIOUS]: "Luxueux",
  [VERY_LUXURIOUS]: "Très luxueux",
};

export const generalConditionKindOptions = _.map(
  generalConditionKindLabels,
  (label, key) => ({
    label: label,
    value: key,
  })
);
