import _ from "lodash";

const CAR = "car";
const JEWEL = "jewel";
const WINE = "wine";
const ART = "art";
const BOOK = "book";
const PRECIOUS_METAL = "precious-metal";
const FURNITURE = "furniture";
const INTANGIBLE_DIGITAL_ASSET = "intangible_digital-asset";
const OTHER_TYPE = "other-type";

// vin, bijoux, art, voiture, livres, métaux précieux, mobilier, actif immatériel/numérique, certificat de fondation,autre type
//              art, books, precious metals, furniture, intangible/digital asset, certificate of foundation, other type

export const kindLabels = {
  [CAR]: "Voiture",
  [JEWEL]: "Bijoux",
  [WINE]: "Vin",
  [ART]: "Art",
  [BOOK]: "Livres",
  [PRECIOUS_METAL]: "Métaux précieux",
  [FURNITURE]: "Mobilier",
  [INTANGIBLE_DIGITAL_ASSET]: "Actif immatériel/numérique",
  [OTHER_TYPE]: "Autre type",
};

export const kindOptions = _.orderBy(
  _.map(kindLabels, (label, key) => ({
    label: label,
    value: key,
  })),
  ["label"]
);
