<template>
  <a-form-item name="energyLabel" label="Label énergétique">
    <a-select
      v-model:value="computedFormState.energyLabel"
      placeholder="Choisissez le label"
    >
      <a-select-option
        v-for="option in energyLabelKindOptions"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-form-item name="specificAspects" label="Particularités">
    <a-textarea
      v-model:value="computedFormState.specificAspects"
      placeholder="Particularités"
      :rows="4"
    />
  </a-form-item>

  <ContainerFieldsHorizontal>
    <template #1>
      <a-form-item name="garden" label="Superficie du jardin (en m2)">
        <InputSurface
          v-model:value="computedFormState.garden"
          placeholder="Renseignez la superficie du jardin"
        />
      </a-form-item>
    </template>
    <template #2>
      <a-form-item name="balcony" label="Superficie du balcon (en m2)">
        <InputSurface
          v-model:value="computedFormState.balcony"
          placeholder="Renseignez la superficie du balcon"
        />
      </a-form-item>
    </template>
  </ContainerFieldsHorizontal>

  <div class="row-fields">
    <a-form-item
      style="display: grid"
      class="form-item-column"
      :key="item.name"
      v-for="item in [
        { label: 'Étage', name: 'floorNumber' },
        { label: 'Pièce(s)', name: 'roomNumber' },
        { label: 'Salle(s) de bain', name: 'bathroomNumber' },
        { label: 'Place(s) de garage', name: 'garageNumber' },
        { label: 'Place(s) de parking', name: 'parkingNumber' },
      ]"
      :name="item.name"
      :label="item.label"
    >
      <a-input-number
        v-model:value="computedFormState[item.name]"
        min="0"
        step="1"
        placeholder="Nombre"
      />
    </a-form-item>
  </div>

  <a-form-item label="Le bien dispose-t-il d’un ascenceur ?" name="elevator">
    <a-checkbox-group>
      <a-checkbox v-model:checked="computedFormState.elevator" />
    </a-checkbox-group>
  </a-form-item>

  <a-form-item label="Quel est l’état général du bien ?">
    <a-radio-group v-model:value="computedFormState.generalCondition">
      <a-radio
        v-for="item in generalConditionKindOptions"
        :key="item.value"
        :value="item.value"
        >{{ item.label }}
      </a-radio>
    </a-radio-group>
  </a-form-item>
</template>

<script>
import InputSurface from "@/components/Fields/InputSurface";
import {
  energyLabelKindOptions,
  generalConditionKindOptions,
  specificAspectsKindOptions,
} from "@/views/App/ValuableObjects/RealEstate/constants";
import ContainerFieldsHorizontal from "@/components/Fields/ContainerFieldsHorizontal";

export default {
  name: "TabOther",
  components: { ContainerFieldsHorizontal, InputSurface },
  props: ["formState"],
  data() {
    return {
      energyLabelKindOptions,
      generalConditionKindOptions,
      specificAspectsKindOptions,
    };
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style>
.row-fields {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
}
.center-options {
  display: grid;
  text-align: center;
}
.form-item-column .ant-col-5 {
  display: contents;
}

.form-item-column .ant-col-5 label {
  font-size: 10px;
  margin: 0px;
}
</style>
