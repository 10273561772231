<template>
  <Workspace>
    <div class="header">
      <span class="title"
        >La valeur affichée pour les biens ci-dessous est celle que vous avez
        bien voulu nous communiquer.<br />Aucune mise à jour automatique n’est
        effectuée.</span
      >
      <ButtonShowCreateForm
        v-if="!isMobile"
        buttonTitle="Ajouter un bien"
        buttonStyle="right: 0;top:0;position:absolute"
        modal-title="Ajouter un bien"
        :addValuableObject="addValuableObject"
        :addRealEstate="addRealEstate"
        :addCompany="addCompany"
      />
    </div>
    <div class="position-relative">
      <FormEditValuableObject
        v-if="currentEdit !== null"
        :valuableObject="currentEdit"
        :onHide="stopEdit"
        :updateValuableObject="updateValuableObject"
      />

      <FormEditRealEstate
        v-if="currentEditRealEstate !== null"
        :realEstate="currentEditRealEstate"
        :onHide="stopEdit"
        :updateRealEstate="updateRealEstate"
      />

      <FormEditCompany
        v-if="currentEditCompany !== null"
        :company="currentEditCompany"
        :onHide="stopEdit"
        :updateCompany="updateCompany"
      />
    </div>

    <div class="container-grid">
      <Card v-for="valuableObject in valuableObjects" :key="valuableObject">
        <div class="item">
          <div class="action">
            <ContextualMenuValuableObject
              :valuableObject="valuableObject"
              :deleteValuableObject="deleteValuableObject"
              :editValuableObject="editValuableObject"
            />
          </div>

          <div>
            <div
              style="display: flex; align-items: center; margin-bottom: 20px"
            >
              <Icon
                :name="getIconValuableObjects(valuableObject.kind)"
                size="thin"
                color="var(--kermony-office-violet)"
                stroke="var(--kermony-office-violet)"
              />
              <h4 class="title" v-if="valuableObject.kind in kindLabels">
                {{ kindLabels[valuableObject.kind] }}
              </h4>
              <h4 class="title" v-else></h4>
            </div>

            <div class="object-name">
              {{ valuableObject.name }}
            </div>
            <div class="value-amount">
              <div class="value">Valeur du bien</div>
              <div class="amount">
                {{ formatAmount(valuableObject.currentValue) }}
              </div>
            </div>

            <!-- AFTER -->
            <div v-if="valuableObject.acquisitionValue !== null && 1 === 2">
              <div>
                Valeur d'acquisition:
                {{ formatAmount(valuableObject.acquisitionValue) }}
              </div>
              <div>
                P/L:
                <span
                  :style="
                    valuableObject.currentValue >
                    valuableObject.acquisitionValue
                      ? { color: 'green' }
                      : { color: 'red' }
                  "
                >
                  {{
                    valuableObject.currentValue -
                    valuableObject.acquisitionValue
                  }}
                  € ({{
                    valuableObject.currentValue >
                    valuableObject.acquisitionValue
                      ? "+"
                      : ""
                  }}{{
                    roundToTwoDecimalPlaces(
                      ((valuableObject.currentValue -
                        valuableObject.acquisitionValue) /
                        valuableObject.acquisitionValue) *
                        100
                    )
                  }}%)
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card v-for="realEstate in realEstates" :key="realEstate">
        <div class="item">
          <div class="action">
            <ContextualMenuImmo
              :realEstate="realEstate"
              :deleteRealEstate="deleteRealEstate"
              :editRealEstate="editRealEstate"
            />
          </div>

          <div>
            <div
              style="display: flex; align-items: center; margin-bottom: 20px"
            >
              <Icon
                name="house"
                size="thin"
                color="var(--kermony-office-violet)"
              />
              <h4 class="title" v-if="realEstate.kind in kindLabels">
                {{ kindLabels[valuableObject.kind] }}
              </h4>
              <h4 class="title" v-else>Bien immobilier</h4>
            </div>

            <div class="object-name" :title="realEstate.address">
              {{ realEstate.address }}
            </div>

            <div class="value-amount">
              <div class="value">Valeur estimée (votre part)</div>
              <div class="amount" v-if="realEstate.ownerValue">
                {{ formatAmount(realEstate.ownerValue) }}
              </div>
              <div class="amount" v-else>
                {{
                  formatAmount(
                    realEstate.currentValue > 0
                      ? realEstate.currentValue
                      : realEstate.acquisitionValue
                  )
                }}
              </div>
            </div>

            <div class="value-amount" v-if="realEstate.ownerValue">
              <div class="value">
                Valeur totale:
                {{
                  formatAmount(
                    realEstate.currentValue > 0
                      ? realEstate.currentValue
                      : realEstate.acquisitionValue
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card v-for="company in companies" :key="company">
        <div class="item">
          <div class="action">
            <ContextualMenuCompany
              :company="company"
              :deleteCompany="deleteCompany"
              :editCompany="editCompany"
            />
          </div>
          <div>
            <div
              style="display: flex; align-items: center; margin-bottom: 20px"
            >
              <Icon
                v-if="
                  (company.kind === 'startup' || company.kind === 'holding') &&
                  company.realEstateCompany !== true
                "
                name="financial"
                size="thin"
                color="var(--kermony-office-violet)"
              />
              <Icon
                v-else-if="
                  company.kind === 'holding' &&
                  company.realEstateCompany === true
                "
                name="house"
                size="thin"
                color="var(--kermony-office-violet)"
              />
              <Icon
                v-else
                name="company"
                size="thin"
                color="var(--kermony-office-violet)"
              />
              <h4 v-if="company.kind in companyKindLabels" class="title">
                {{ companyKindLabels[company.kind] }}
              </h4>
              <h4 v-else class="title">Société</h4>
            </div>

            <div class="object-name">
              {{ company.name }}
            </div>

            <div class="value-amount">
              <div class="value">Valeur estimée (votre part)</div>
              <div class="amount" v-if="company.ownerValue">
                {{ formatAmount(company.ownerValue) }}
              </div>
              <div class="amount" v-else>
                {{ formatAmount(company.estimatedValue) }}
              </div>
            </div>

            <div class="value-amount" v-if="company.ownerValue">
              <div class="value">
                Valeur totale: {{ formatAmount(company.estimatedValue) }}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <ButtonShowCreateForm
        v-if="isMobile"
        class="wrapper-btn-mobile-full-width"
        buttonTitle="Ajouter un bien"
        buttonStyle="right: 0;top:0;position:absolute"
        modal-title="Ajouter un bien"
        :addValuableObject="addValuableObject"
        :addRealEstate="addRealEstate"
        :addCompany="addCompany"
      />
    </div>
  </Workspace>
</template>

<script>
import FormEditValuableObject from "@/views/App/ValuableObjects/FormEditValuableObject";
import ValuableObjectApi from "@/api/valuableObject";
import RealEstateApi from "@/api/realEstate";
import { kindLabels } from "./constants";
import { formatAmount, roundToTwoDecimalPlaces } from "@/utils/number";
import { realEstateKindLabels } from "@/views/App/ValuableObjects/RealEstate/constants";
import { kindLabels as companyKindLabels } from "@/views/App/ValuableObjects/Company/constants";
import ContextualMenuValuableObject from "@/views/App/ValuableObjects/ContextualMenuValuableObject";
import _ from "lodash";
import ContextualMenuImmo from "@/views/App/ValuableObjects/RealEstate/ContextualMenuImmo";
import FormEditRealEstate from "@/views/App/ValuableObjects/RealEstate/FormEditRealEstate";
import CompanyApi from "@/api/company";
import FormEditCompany from "@/views/App/ValuableObjects/Company/FormEditCompany";
import ContextualMenuCompany from "@/views/App/ValuableObjects/Company/ContextualMenuCompany";
import Card from "@/components/Cards/Card";
import Icon from "@/components/Icons/Icon";
import Workspace from "@/components/Workspaces/Workspace";
import { isMobile } from "@/utils/isMobile";
import ButtonShowCreateForm from "@/views/App/ValuableObjects/ButtonShowCreateForm.vue";

export default {
  name: "ValuableObjects",
  components: {
    ButtonShowCreateForm,
    Workspace,
    Icon,
    Card,
    ContextualMenuCompany,
    FormEditCompany,
    FormEditRealEstate,
    ContextualMenuImmo,
    ContextualMenuValuableObject,
    FormEditValuableObject,
  },
  data() {
    return {
      visible: false,
      valuableObjects: null,
      realEstates: null,
      companies: null,
      realEstateKindLabels,
      kindLabels,
      companyKindLabels,
      formatAmount,
      roundToTwoDecimalPlaces,
      currentEdit: null,
      currentEditRealEstate: null,
      currentEditCompany: null,
      isMobile: isMobile(),
    };
  },
  methods: {
    getIconValuableObjects(kind) {
      switch (kind) {
        case "jewel":
          return "bijoux";
        case "wine":
          return "bottle";
        default:
          return "logo";
      }
    },
    showModal() {
      this.visible = true;
    },
    addValuableObject(item) {
      this.valuableObjects.push(item);
    },
    updateValuableObject(item) {
      this.valuableObjects = _.map(this.valuableObjects, (valuableObject) => {
        if (valuableObject._id === item._id) {
          return item;
        }

        return { ...valuableObject };
      });
    },
    deleteValuableObject(item) {
      ValuableObjectApi.deleteValuableObject(item._id).then(() => {
        this.valuableObjects = _.filter(
          this.valuableObjects,
          (valuableObject) => valuableObject._id !== item._id
        );
      });
    },
    editValuableObject(item) {
      this.currentEdit = item;
    },
    addRealEstate(item) {
      this.realEstates.push(item);
    },
    updateRealEstate(item) {
      this.realEstates = _.map(this.realEstates, (realEstate) => {
        if (realEstate._id === item._id) {
          return item;
        }

        return { ...realEstate };
      });
    },
    deleteRealEstate(item) {
      RealEstateApi.deleteRealEstate(item._id).then(() => {
        this.realEstates = _.filter(
          this.realEstates,
          (realEstate) => realEstate._id !== item._id
        );
      });
    },
    editRealEstate(item) {
      this.currentEditRealEstate = item;
    },
    addCompany(item) {
      this.companies.push(item);
    },
    updateCompany(item) {
      this.companies = _.map(this.companies, (company) => {
        if (company._id === item._id) {
          return item;
        }

        return { ...company };
      });
    },
    deleteCompany(item) {
      CompanyApi.deleteCompany(item._id).then(() => {
        this.companies = _.filter(
          this.companies,
          (company) => company._id !== item._id
        );
      });
    },
    editCompany(item) {
      this.currentEditCompany = item;
    },
    stopEdit() {
      this.currentEdit = null;
      this.currentEditRealEstate = null;
      this.currentEditCompany = null;
    },
  },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Mes biens",
      subtitle: "Abondance de biens ne nuit pas",
    });
    ValuableObjectApi.getValuableObjects().then((data) => {
      this.valuableObjects = data;
    });
    RealEstateApi.getRealEstates().then((data) => {
      this.realEstates = data;
    });
    CompanyApi.getCompanies().then((data) => {
      this.companies = data;
    });
  },
};
</script>

<style scoped>
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 24px;
}
.title {
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: var(--kermony-office-bleu-1);
  margin: 0 0 0 4px;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
.container-grid {
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
}

.container-grid .item {
  position: relative;
  padding: 16px;
}

.container-grid .item svg {
  width: 16px;
  height: 16px;
}

.container-grid .item .title {
  margin-left: 4px;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--kermony-office-violet);
}

.container-grid .item .object-name {
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: var(--kermony-office-bleu-1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-grid .item .value-amount {
  display: flex;
  /* This margin is not define in figma */
  /* It was requested by alex */
  margin-top: 5px;
}

.container-grid .item .value {
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: var(--kermony-office-rose);
  align-self: center;
}

.container-grid .item .amount {
  margin-left: auto;
  font-weight: 610;
  font-size: 21px;
  line-height: 27px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--kermony-office-rose);
}

.item:hover .action {
  display: block;
}

.action {
  display: none;
  position: absolute;
  right: 15px;
  margin-top: -4px;
}

.card-body-custom {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(207, 212, 226);
  padding: 15px !important;
}
</style>
