<template>
  <a-tabs v-model:activeKey="computedActiveKey" animated>
    <a-tab-pane key="1" tab="Description">
      <TabDescription v-model:formState="computedFormState" :mapId="mapId" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="Propriétaires">
      <TabProperty
        v-model:formState="computedFormState"
        :familyMembers="familyMembers"
      />
    </a-tab-pane>
    <a-tab-pane key="3" tab="Autre">
      <TabOther v-model:formState="computedFormState" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TabDescription from "@/views/App/ValuableObjects/RealEstate/Tabs/TabDescription";
import TabProperty from "@/views/App/ValuableObjects/RealEstate/Tabs/TabProperty";
import FamilyApi from "@/api/family";
import TabOther from "@/views/App/ValuableObjects/RealEstate/Tabs/TabOther";

/*
Fields required

address
realEstateKind
acquisitionValue
acquisitionDate
habitableSurface
owners
 */

export default {
  name: "FormCreateImmo",
  components: { TabOther, TabProperty, TabDescription },
  props: ["formState", "formRef", "activeKey", "mapId"],
  data() {
    return {
      familyMembers: null,
    };
  },
  computed: {
    computedFormRef: {
      get: function () {
        return this.formRef;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
    computedActiveKey: {
      get: function () {
        return this.activeKey;
      },
      set: function (newOptions) {
        this.$emit("update:activeKey", newOptions);
      },
    },
  },
  mounted() {
    FamilyApi.getFamilyMembers().then((data) => {
      this.familyMembers = data.familyMembers;
    });
  },
  setup() {
    const formItemLayout = {
      labelCol: { span: 4 },
    };

    return {
      formItemLayout,
    };
  },
};
</script>

<style scoped>
.ant-tabs {
  margin-top: -16px;
}
</style>
