<template>
  <a-form-item
    label="S’agit-il de votre résidence principale ?"
    name="mainResidence"
  >
    <a-checkbox v-model:checked="computedFormState.mainResidence" />
  </a-form-item>

  <a-form-item
    name="realEstateKind"
    label="Type d’immobilier"
    :rules="[
      { required: true, message: 'Veuillez saisir le type d’immobilier !' },
    ]"
  >
    <a-radio-group
      v-model:value="computedFormState.realEstateKind"
      :disabled="computedFormState.mainResidence === true"
    >
      <a-radio
        v-for="item in realEstateKindOptions"
        :key="item.value"
        :value="item.value"
        >{{ item.label }}
      </a-radio>
    </a-radio-group>
  </a-form-item>

  <a-form-item
    name="address"
    label="Adresse"
    :rules="[{ required: true, message: 'Veuillez saisir l\'adresse !' }]"
  >
    <vue-google-autocomplete
      ref="address"
      :id="mapId"
      classname="ant-input"
      placeholder="Renseignez l’adresse du bien"
      v-on:placechanged="getAddressData"
      @blur="onBlurAddress()"
    >
    </vue-google-autocomplete>
  </a-form-item>

  <a-form-item
    name="address_google_maps"
    label="address_google_maps"
    style="display: none"
  >
    <a-input
      v-model:value="computedFormState.address_google_maps"
      type="hidden"
    />
  </a-form-item>

  <ContainerFieldsHorizontal>
    <template #1>
      <a-form-item
        name="currentValue"
        label="Valeur estimée"
        :rules="[
          {
            required: true,
            message: 'Veuillez saisir la valeur estimée',
          },
        ]"
      >
        <InputCurrency
          v-model:value="computedFormState.currentValue"
          placeholder="Renseignez la valeur estimée"
          :step="10000"
        />
      </a-form-item>
    </template>
    <template #2>
      <a-form-item
        name="acquisitionDate"
        label="Date d'acquisition"
        :rules="[
          { required: true, message: 'Veuillez saisir la date d\'acquisition' },
        ]"
      >
        <a-date-picker
          class="w-100"
          v-model:value="computedFormState.acquisitionDate"
          placeholder="JJ/MM/AAAA"
          format="DD/MM/YYYY"
        />
      </a-form-item>
    </template>
  </ContainerFieldsHorizontal>

  <ContainerFieldsHorizontal>
    <template #1>
      <a-form-item name="constructionYear" label="Année de construction">
        <a-input-number
          class="w-100"
          v-model:value="computedFormState.constructionYear"
          min="1700"
          max="2099"
          step="1"
          placeholder="Renseignez l’année de construction"
        />
      </a-form-item>
    </template>
    <template #2>
      <a-form-item
        name="habitableSurface"
        label="Surface habitable (en m2)"
        :rules="[
          { required: true, message: 'Veuillez saisir la surface habitable' },
        ]"
      >
        <InputSurface
          placeholder="Renseignez la surface habitable"
          v-model:value="computedFormState.habitableSurface"
        />
      </a-form-item>
    </template>
  </ContainerFieldsHorizontal>
</template>

<script>
import InputCurrency from "@/components/Fields/InputCurrency";
import InputSurface from "@/components/Fields/InputSurface";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import {
  PERSONAL_PROPERTY,
  realEstateKindOptions,
} from "@/views/App/ValuableObjects/RealEstate/constants";
import ContainerFieldsHorizontal from "@/components/Fields/ContainerFieldsHorizontal";

export default {
  name: "TabDescription",
  components: {
    ContainerFieldsHorizontal,
    InputSurface,
    InputCurrency,
    VueGoogleAutocomplete,
  },
  props: ["formState", "mapId"],
  data() {
    return {
      realEstateKindOptions,
    };
  },
  mounted() {
    this.$refs.address.update(this.formState.address);
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.computedFormState.address = placeResultData.formatted_address;
      this.computedFormState.address_google_maps = addressData;
    },
    onBlurAddress() {
      this.$refs.address.update(this.computedFormState.address);
    },
  },
  watch: {
    "computedFormState.mainResidence": {
      handler(val) {
        if (val === true) {
          this.computedFormState.realEstateKind = PERSONAL_PROPERTY;
        }
      },
      deep: true,
    },
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style scoped></style>
