<template>
  <a-dropdown :trigger="['click']" @click="dropdownClick" :visible="visible">
    <Icon size="medium" name="more" color="var(--kermony-gris-1)"></Icon>

    <template #overlay>
      <a-menu @click="menuClick">
        <a-menu-item key="edit" @click="editValuableObject(valuableObject)"
          >Modifier le bien</a-menu-item
        >
        <a-menu-item key="delete">
          <a-popconfirm
            placement="bottomLeft"
            title="Êtes-vous sûr de vouloir supprimer ce bien ?"
            ok-text="Oui"
            cancel-text="Non"
            @confirm="deleteValuableObject(valuableObject)"
          >
            <a href="#">Supprimer le bien</a>
          </a-popconfirm>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "ContextualMenuValuableObject",
  components: { Icon },
  props: ["valuableObject", "deleteValuableObject", "editValuableObject"],
  data() {
    return {
      visible: false,
    };
  },
  unmounted() {
    document.removeEventListener("click", this.clickOutsideEvent);
  },
  methods: {
    clickOutsideEvent() {
      this.visible = false;
      document.removeEventListener("click", this.clickOutsideEvent);
    },
    dropdownClick() {
      this.visible = true;
      document.removeEventListener("click", this.clickOutsideEvent);
      setTimeout(
        () => document.addEventListener("click", this.clickOutsideEvent),
        0
      );
    },
    menuClick(e) {
      e.domEvent.stopPropagation();
      const { key } = e;
      if (key === "delete") {
        return;
      }

      this.visible = false;
    },
  },
};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
