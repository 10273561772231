<template>
  <ContextualMenu>
    <a-menu-item key="edit" @click="editRealEstate(realEstate)"
      >Modifier le bien</a-menu-item
    >
    <a-menu-item key="delete">
      <a-popconfirm
        placement="bottomLeft"
        title="Êtes-vous sûr de vouloir supprimer ce bien ?"
        ok-text="Oui"
        cancel-text="Non"
        @confirm="deleteRealEstate(realEstate)"
      >
        <a href="#">Supprimer le bien</a>
      </a-popconfirm>
    </a-menu-item>
  </ContextualMenu>
</template>

<script>
import ContextualMenu from "@/views/App/ValuableObjects/ContextualMenu";
export default {
  name: "ContextualMenuImmo",
  components: { ContextualMenu },
  props: ["realEstate", "deleteRealEstate", "editRealEstate"],
};
</script>

<style scoped></style>
