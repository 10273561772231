// deduction available
import HTTP from "@/axios";

export default {
  getRealEstates() {
    return new Promise((resolve, reject) => {
      HTTP.get("real_estate/")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createRealEstate(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("real_estate/", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editRealEstate(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(`real_estate/${id}/`, data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRealEstate(id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`real_estate/${id}/`)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
