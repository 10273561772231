<template>
  <div>
    <Button
      @click="setVisible"
      size="medium"
      theme="primary"
      icon-left="plus"
      >{{ buttonTitle }}</Button
    >

    <FormCreateValuableObject
      v-if="this.visible"
      buttonTitle="Ajouter un bien"
      buttonStyle="right: 0;top:0;position:absolute"
      modal-title="Ajouter un bien"
      :addValuableObject="addValuableObject"
      :addRealEstate="addRealEstate"
      :addCompany="addCompany"
      :hideModal="setInvisible"
    />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import FormCreateValuableObject from "@/views/App/ValuableObjects/FormCreateValuableObject.vue";

/*

//onclick

visible = true;
      stateRef = State.Selection;
      setCategory(null);
 */

export default {
  name: "ButtonShowCreateForm",
  components: { FormCreateValuableObject, Button },
  props: [
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "addValuableObject",
    "addRealEstate",
    "addCompany",
  ],
  methods: {
    setVisible() {
      this.visible = true;
    },
    setInvisible() {
      this.visible = false;
    },
  },
  data() {
    return { visible: false };
  },
};
</script>

<style scoped></style>
