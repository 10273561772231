<template>
  <a-spin v-if="familyMembers === null" />
  <div v-else>
    <a-form-item
      class="zero-control-input"
      name="owners"
      :rules="[
        {
          required: true,
          message: 'Veuillez saisir au moins un propriétaire ! ',
        },
      ]"
    >
    </a-form-item>

    <div
      v-for="(beneficiary, index) in computedFormState.owners"
      :key="beneficiary.owner ?? 'new'"
      style="display: grid; grid-template-columns: 1fr auto; gap: 20px"
    >
      <div>
        <a-form-item
          style="flex-grow: 1"
          :name="['owners', index, 'owner']"
          label="Nom du propriétaire"
          :rules="[
            {
              required: true,
              message: 'Veuillez saisir le nom du propriétaire ! ',
            },
          ]"
        >
          <a-select v-model:value="beneficiary.owner">
            <a-select-option
              v-for="option in listBeneficiaries(index)"
              :value="option._id"
              :key="option._id"
            >
              {{ option.firstName }} {{ option.lastName }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :name="['owners', index, 'ownershipType']"
          label="Type de propriété"
          :rules="[
            {
              required: true,
              message: 'Veuillez saisir le type de propriété ! ',
            },
          ]"
        >
          <a-select v-model:value="beneficiary.ownershipType">
            <a-select-option
              v-for="option in givenInOptions"
              :value="option.value"
              :key="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :name="['owners', index, 'percentage']"
          label="Pourcentage de propriété"
          :rules="[
            {
              required: true,
              message: 'Veuillez saisir le pourcentage de propriété ! ',
            },
          ]"
        >
          <InputPercentage
            v-model:value="beneficiary.percentage"
            placeholder="Pourcentage de propriété"
          />
        </a-form-item>
      </div>
      <MinusCircleOutlined
        style="margin-top: 8px"
        @click="removeOwner(index)"
      />
    </div>

    <a-form-item>
      <a-button type="dashed" block @click="addOwner">
        <PlusOutlined style="position: relative; top: -3px" />
        <span>Ajouter un propriétaire</span>
      </a-button>
    </a-form-item>
  </div>
</template>

<script>
import { realEstateKindOptions } from "@/views/App/ValuableObjects/RealEstate/constants";
import { givenInOptions } from "@/views/App/Transmission/Donations/constants";
import InputPercentage from "@/components/Fields/InputPercentage";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "TabProperty",
  components: { InputPercentage, MinusCircleOutlined, PlusOutlined },
  props: ["formState", "familyMembers"],
  data() {
    return {
      realEstateKindOptions,
      givenInOptions,
    };
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
  methods: {
    listBeneficiaries() {
      return this.familyMembers;
    },
    addOwner() {
      this.computedFormState.owners.push({
        owner: null, // Nom du propiétaire * :
        ownershipType: null, // Type de propriété *:
        percentage: null, // Pourcentage de propriété * :
      });
    },
    removeOwner(index) {
      if (index !== -1) {
        this.computedFormState.owners.splice(index, 1);
      }
    },
  },
};
</script>

<style>
.zero-control-input .ant-form-item-control-input {
  min-height: 0;
}
</style>
