<template>
  <div class="position-relative">
    <div class="ant-input-number w-100 text-grey">
      <div class="ant-input-number-input-wrap">
        <input
          autocomplete="off"
          role="spinbutton"
          step="1"
          tabindex="-1"
          class="ant-input-number-input"
          :value="formatted"
        />
      </div>
    </div>
    <a-input-number
      :placeholder="placeholder"
      class="w-100 position-absolute-important"
      style="top: 0; left: 0; background: transparent"
      :value="value"
      @change="(newValue) => $emit('update:value', newValue)"
      :min="0"
    />
  </div>
</template>

<script>
export default {
  name: "InputSurface",
  props: ["value", "placeholder"],
  computed: {
    formatted() {
      const v = this.value;
      if (!v || v.length === 0) {
        return ``;
      }
      return `${v} m2`;
    },
  },
};
</script>

<style scoped>
.text-grey {
  color: darkgrey;
}
</style>
